export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'Dashboard',
        to: '/dashboard',
        icon: 'cil-speedometer'
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Pages']
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Site Constructor',
        icon: 'cil-puzzle',
        items: [
          {
            name: 'Slider',
            to: '/site-constructor/slider'
          },

          {
            name: 'Pages',
            to: '/site-constructor/pages',
          }
        ]
      }
    ]
  }
]